import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import './BreadCrumbDropdown.css';

export default function AppDropdown({ items, onNavigateTo }) {
  if (items.length === 0) return null;
  return (
    <Dropdown text="Navigate to" button compact className="breadCrumbDropdown">
      <Dropdown.Menu>
        {items.map(item => (
          <Dropdown.Item key={item} text={item} value={item} onClick={() => onNavigateTo(item)} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
