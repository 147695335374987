/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { NavigationControl, WebMercatorViewport } from 'react-map-gl';
import ReactResizeDetector from 'react-resize-detector';
import { Icon } from 'semantic-ui-react';

import IntroLayer from './IntroLayer/IntroLayer';
import PlacesLayer from './PlacesLayer/PlacesLayer';
import BasemapToggle from './BasemapToggle/BasemapToggle';

class Atlas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxBounds: null,
      rasterOpacity: 1,
      opacityInterval: null,
      viewport: {
        width: 400,
        height: 400,
        latitude: 45.846,
        longitude: 3.65,
        zoom: 4,
        bearing: 0,
        pitch: 0,
      },
    };
    this.onResize = this.onResize.bind(this);
    this.toggleBasemap = this.toggleBasemap.bind(this);
  }

  componentDidMount() {
    if (this.props.places.length > 0) {
      const { places } = this.props;
      const maxBounds = [
        [
          places.reduce(
            (minLng, c) => Math.min(minLng, c.data.Longitude || c.data.Longitude__Current_),
            Infinity
          ),
          places.reduce(
            (minLat, c) => Math.min(minLat, c.data.Latitude || c.data.Latitude__Current_),
            Infinity
          ),
        ],
        [
          places.reduce(
            (maxLng, c) => Math.max(maxLng, c.data.Longitude || c.data.Longitude__Current_),
            -Infinity
          ),
          places.reduce(
            (maxLat, c) => Math.max(maxLat, c.data.Latitude || c.data.Latitude__Current_),
            -Infinity
          ),
        ],
      ];
      this.zoomToBounds(maxBounds);
      this.setState({ maxBounds });
    }
  }

  onResize(width, height) {
    const { viewport } = this.state;
    viewport.width = width;
    viewport.height = height;
    this.setState({ viewport });
  }

  zoomToBounds(bounds, transitionOptions = {}) {
    // construct a viewport instance from the current state
    const viewport = new WebMercatorViewport(this.state.viewport);
    const { longitude, latitude, zoom } = viewport.fitBounds(bounds, {
      padding: 40,
    });

    this.setState(prevState => ({
      viewport: {
        ...prevState.viewport,
        longitude,
        latitude,
        zoom: Math.ceil(zoom),
        ...transitionOptions,
      },
    }));
  }

  toggleBasemap() {
    clearInterval(this.state.opacityInterval);
    const i = this.state.rasterOpacity < 1 ? 0.1 : -0.1;
    this.setState({
      opacityInterval: setInterval(() => {
        let { rasterOpacity } = this.state;
        rasterOpacity += i;
        if (rasterOpacity < 0 || rasterOpacity > 1) return;
        this.setState({ rasterOpacity });
      }, 120),
    });
  }

  render() {
    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
        <ReactMapGL
          {...this.state.viewport}
          width="100%"
          height="calc(100vh - 80px - 45px)"
          maxZoom={this.state.intro ? 5.5 : 19}
          minZoom={4}
          maxBounds={this.state.maxBounds}
          mapStyle={
            this.props.intro
              ? 'mapbox://styles/axismaps/ck5fhj0g50klk1ipohauo92u9/draft'
              : 'mapbox://styles/axismaps/ckbf8r8173w6i1innp70b8rye'
          }
          onViewportChange={viewport => this.setState({ viewport })}
          mapboxApiAccessToken="pk.eyJ1IjoiYXhpc21hcHMiLCJhIjoieUlmVFRmRSJ9.CpIxovz1TUWe_ecNLFuHNg"
        >
          {this.props.intro ? (
            <IntroLayer />
          ) : (
            <PlacesLayer
              city={this.props.city}
              places={this.props.places}
              rasterOpacity={this.state.rasterOpacity}
            />
          )}
          <div style={{ position: 'absolute', left: 15, top: 15 }}>
            <NavigationControl showCompass={false} />
            {!this.props.intro && (
              <BasemapToggle icon={<Icon name="map" />} handler={this.toggleBasemap} />
            )}
          </div>
        </ReactMapGL>
      </ReactResizeDetector>
    );
  }
}

Atlas.propTypes = {
  city: PropTypes.string,
  intro: PropTypes.bool,
  places: PropTypes.arrayOf(PropTypes.object),
};

Atlas.defaultProps = {
  intro: false,
  places: [],
  city: null,
};

export default Atlas;
