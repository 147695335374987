import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CityMarker from '../CityMarker/CityMarker';

const IntroLayer = () => (
  <StaticQuery
    query={graphql`
      query {
        allAirtable(filter: { table: { eq: "Cities" } }) {
          edges {
            node {
              data {
                Name
                Country
                City_Latitude
                City_Longitude
                Left
                Offset
              }
            }
          }
        }
        deventer: file(relativePath: { eq: "map_thumbs/deventer.jpg" }) {
          ...mapImage
        }
        exeter: file(relativePath: { eq: "map_thumbs/exeter.jpg" }) {
          ...mapImage
        }
        hamburg: file(relativePath: { eq: "map_thumbs/hamburg.jpg" }) {
          ...mapImage
        }
        trento: file(relativePath: { eq: "map_thumbs/trento.jpg" }) {
          ...mapImage
        }
        valencia: file(relativePath: { eq: "map_thumbs/valencia.jpg" }) {
          ...mapImage
        }
      }
    `}
    render={query => (
      <>
        {query.allAirtable.edges.map(({ node: { data } }) => (
          <CityMarker
            key={data.Name}
            name={data.Name}
            longitude={data.City_Longitude}
            latitude={data.City_Latitude}
            country={data.Country}
            direction={data.Left ? 'left' : ''}
            offsetLeft={data.Offset}
            image={query[data.Name.toLowerCase()].childImageSharp.fixed.src}
          />
        ))}
      </>
    )}
  />
);

export default IntroLayer;

export const query = graphql`
  fragment mapImage on File {
    childImageSharp {
      fixed(width: 90, height: 90) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;
