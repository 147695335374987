import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';

import './PlaceMarker.css';

class PlaceMarker extends PureComponent {
  render() {
    const onMouseOver = e => {
      this.props.showProbe({
        x: e.clientX,
        y: e.clientY,
        thumb: this.props.thumb,
        title: this.props.name,
      });
    };

    return (
      <Marker
        key={this.props.name}
        longitude={this.props.longitude}
        latitude={this.props.latitude}
        offsetLeft={-20}
        offsetTop={-20}
      >
        <div
          className="placeMarker"
          role="button"
          tabIndex={-1}
          aria-label={this.props.name}
          onKeyPress={this.props.handler}
          onClick={this.props.handler}
          onMouseOver={onMouseOver}
          onFocus={onMouseOver}
          onMouseOut={this.props.hideProbe}
          onBlur={this.props.hideProbe}
        />
      </Marker>
    );
  }
}

PlaceMarker.propTypes = {
  name: PropTypes.string.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  handler: PropTypes.func.isRequired,
  showProbe: PropTypes.func.isRequired,
  hideProbe: PropTypes.func.isRequired,
  thumb: PropTypes.shape().isRequired,
};

export default PlaceMarker;
