/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BaseControl } from 'react-map-gl';

class BasemapToggle extends BaseControl {
  _render() {
    return (
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
        <button
          type="button"
          style={{ marginTop: '7px' }}
          className={`mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-reset ${
            this.props.buttonText ? 'text' : ''
          }`}
          onClick={this.props.handler}
        >
          {this.props.icon}
        </button>
      </div>
    );
  }
}

export default BasemapToggle;
