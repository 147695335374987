import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Image } from 'semantic-ui-react';

const DataProbe = ({ title, thumb, position }) => (
  <Segment
    compact
    className="dataProbe"
    style={{ position: 'fixed', left: position[0] + 15, top: position[1] + 5 }}
  >
    <Image circular inline src={thumb} />
    <h4 style={{ display: 'inline', marginLeft: 15, marginRight: 15 }}>{title}</h4>
  </Segment>
);

DataProbe.propTypes = {
  thumb: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DataProbe;
