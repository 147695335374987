/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Source, Layer } from 'react-map-gl';

import DataProbe from '../DataProbe/DataProbe';

import PlaceMarker from '../PlaceMarker/PlaceMarker';

class PlacesLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProbe: false,
      probeLocation: null,
      probeTitle: null,
      probeThumb: null,
    };
  }

  render() {
    const { places, city, rasterOpacity } = this.props;
    return (
      <>
        <Source
          id={city}
          type="raster"
          tiles={[
            `https://s3.amazonaws.com/hidden-cities.axismaps.io/tiles/${city}/{z}/{x}/{y}.png`,
          ]}
          scheme="tms"
        >
          <Layer id={city} type="raster" paint={{ 'raster-opacity': rasterOpacity }} />
        </Source>
        {places.map(({ data }) => (
          <PlaceMarker
            key={data.Title}
            name={data.Title}
            longitude={data.Longitude || data.Longitude__Current_}
            latitude={data.Latitude || data.Latitude__Current_}
            thumb={data.Image ? data.Image.localFiles[0].childImageSharp.thumb.src : null}
            handler={() => {
              navigate(`/${city.toLowerCase()}/${data.Slug}`);
            }}
            showProbe={placeData => {
              this.setState({
                showProbe: true,
                probeLocation: [placeData.x, placeData.y],
                probeTitle: placeData.title,
                probeThumb: placeData.thumb,
              });
            }}
            hideProbe={() => this.setState({ showProbe: false })}
          />
        ))}
        {this.state.showProbe && (
          <DataProbe
            title={this.state.probeTitle}
            thumb={this.state.probeThumb}
            position={this.state.probeLocation}
          />
        )}
      </>
    );
  }
}

PlacesLayer.propTypes = {
  places: PropTypes.arrayOf(PropTypes.object).isRequired,
  city: PropTypes.string.isRequired,
  rasterOpacity: PropTypes.number,
};

PlacesLayer.defaultProps = {
  rasterOpacity: 1,
};

export default PlacesLayer;
