import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Marker } from 'react-map-gl';
import { Image, Segment, Header } from 'semantic-ui-react';

import './CityMarker.css';

class CityMarker extends PureComponent {
  render() {
    return (
      <Marker
        className={`cityMarker ${this.props.direction === 'left' ? 'left' : 'right'}`}
        key={this.props.name}
        longitude={this.props.longitude}
        latitude={this.props.latitude}
        offsetLeft={
          this.props.direction === 'left'
            ? this.props.offsetLeft || -200
            : this.props.offsetLeft || -40
        }
        offsetTop={-40}
      >
        {this.props.direction !== 'left' && (
          <Image
            circular
            src={this.props.image}
            onClick={() => navigate(`/${this.props.name.toLowerCase()}`)}
          />
        )}
        <Segment onClick={() => navigate(`/${this.props.name.toLowerCase()}`)}>
          <Header as="h4">{this.props.name}</Header>
          {this.props.country}
        </Segment>
        {this.props.direction === 'left' && (
          <Image
            circular
            src={this.props.image}
            onClick={() => navigate(`/${this.props.name.toLowerCase()}`)}
          />
        )}
      </Marker>
    );
  }
}

CityMarker.propTypes = {
  name: PropTypes.string.isRequired,
  longitude: PropTypes.number.isRequired,
  latitude: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  direction: PropTypes.string,
  offsetLeft: PropTypes.number,
};

CityMarker.defaultProps = {
  direction: null,
  offsetLeft: 0,
};

export default CityMarker;
