import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import BreadcrumbDropdown from './BreadcrumbDropdown/BreadCrumbDropdown';
import { slugify } from '../../utils';

import './Breadcrumb.css';

const generateSectionsFromPathname = pathname => {
  if (!pathname) return;

  const sections = pathname.split('/').reduce((acc, curr) => {
    // arr.split('/') returns ['', ...], this ignores the empty string at arr[0].
    if (curr.length > 1) {
      return [
        ...acc,
        {
          key: curr,
          content: curr,
          link: true,
          onClick: () => {
            const sectionKeys = acc.map(section => {
              return section.key;
            });
            navigate(`/${[...sectionKeys, curr].join('/')}`);
          },
        },
      ];
    }
    return acc;
  }, []);
  return sections;
};

const generateDropdownItemsFromSections = (sections, places, cityNames) => {
  const selectionTuple = { 0: 'home', 1: 'city', 2: 'place', 3: 'object' };

  const selectedItemType = selectionTuple[sections.length];

  const selectedItemKey = sections.length > 0 ? sections[sections.length - 1].key : null;

  const getNamesFromDataArray = places => {
    return places.map(place => place.data.Slug);
  };

  const getObjectNamesFromSelectedPlace = (selectedItemKey, places) => {
    const selectedPlace = places.find(place => place.data.Slug === selectedItemKey);

    const selectedPlaceObjects = selectedPlace ? selectedPlace.data.Object : null;

    if (selectedPlaceObjects) {
      return getNamesFromDataArray(selectedPlaceObjects);
    }
    return [];
  };

  switch (selectedItemType) {
    case 'home': {
      return cityNames;
    }
    case 'city': {
      return getNamesFromDataArray(places);
    }
    case 'place': {
      return getObjectNamesFromSelectedPlace(selectedItemKey, places);
    }
    case 'object': {
      return [];
    }
    default:
      break;
  }
};

export default ({ pathname, places }) => {
  const {
    allAirtable: { edges: cities },
  } = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "Cities" } }) {
        edges {
          node {
            data {
              Name
            }
          }
        }
      }
    }
  `);

  const cityNames = cities.map(city => {
    return city.node.data.Name;
  });

  const sections = generateSectionsFromPathname(pathname);

  const dropdownItems = generateDropdownItemsFromSections(sections, places, cityNames);

  const handleOnNavigateTo = itemName => {
    navigate(slugify(itemName));
  };

  return (
    <div className="breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Section link onClick={() => navigate('/')}>
          Home
        </Breadcrumb.Section>
        {sections.length > 0 && <Breadcrumb.Divider icon="right chevron" />}
      </Breadcrumb>
      <Breadcrumb icon="right angle" sections={sections} />
      {dropdownItems.length > 0 && (
        <Breadcrumb>
          <Breadcrumb.Divider icon="right chevron" />
        </Breadcrumb>
      )}
      <BreadcrumbDropdown
        items={dropdownItems}
        onNavigateTo={itemName => handleOnNavigateTo(itemName)}
      />
    </div>
  );
};
